<template>
    <div
        id="app"
        :class="theme"
    >
        <router-view></router-view>
        <!--
        <ChatMenu v-if="initialized && $token && chatDisabled" />
        -->
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'

import { useChoicesStore } from '@/stores/choices'
import { useAppStore } from '@/stores/app'
import { useCompanyStore } from '@/stores/company'
import { useAuthStore } from '@/stores/auth'
import { useThemeStore } from '@/stores/theme'
//import ChatMenu from '@/modules/chat/components/ChatMenu.vue'

export default {
    name: 'App',
    //components: { ChatMenu },
    computed: {
        ...mapState(useThemeStore, ['theme']),
        ...mapState(useAppStore, ['initialized']),
        chatDisabled() {
            return import.meta.env.VITE_APP_CHAT !== 'false'
        },
    },
    watch: {
        $token(newval) {
            this.setBearerToken(newval)
            this.$rest.setToken(newval)
        },
    },
    async mounted() {
        await this.fetchChoices()

        const companyData = await this.$request('/company')
        this.initCompany(companyData)

        this.setBearerToken(this.$token)
        this.$rest.setToken(this.$token)

        const { connected, build } = await this.$ping()
        this.setBuild(build)

        if (!connected) {
            this.clearAuth()
        }

        this.initApp()

        if (!connected) {
            if (this.$route.meta.noAuth) {
                return
            }
            if (!this.$route.path.endsWith('/login')) {
                this.$router.replace('/login')
            }
        } else {
            if (import.meta.env.VITE_APP_PING !== 'false') {
                setInterval(async () => {
                    await this.checkConnection()
                }, 30000)
            }
        }
    },
    methods: {
        ...mapActions(useAppStore, ['initApp', 'setBuild']),
        ...mapActions(useCompanyStore, ['initCompany']),
        ...mapActions(useChoicesStore, ['fetchChoices']),
        ...mapActions(useAuthStore, ['initAuth', 'clearAuth']),
        async checkConnection() {
            const { connected, build } = await this.$ping()
            this.setBuild(build)

            if (!connected) {
                this.clearAuth()
                if (!this.$route.path.endsWith('/login')) {
                    this.$router.replace('/login')
                }
            }
        },
    },
}
</script>

<style lang="scss">
@import './assets/broly.css';
@import './assets/broly-file.css';
@import './assets/broly-timeline.css';
@import './assets/broly-badge.css';
@import './assets/t3admin';
</style>
