export default [
    {
        name: 'POSOrderList',
        path: 'banking/pos/orders',
        component: () => import('./views/POSOrderList.vue'),
        meta: {
            title: 'POS Orders',
            perms: 'pos_orders',
            icon: 'fa-solid fa-credit-card',
        },
    },
    {
        name: 'IncomingTransferList',
        path: 'banking/transfers/incoming',
        component: () => import('./views/IncomingTransferList.vue'),
        meta: {
            title: 'Incoming Transfers',
            perms: 'incoming_transfers',
            icon: 'fa-solid fa-exchange-alt',
        },
    },
    {
        name: 'IncomingTransferCreate',
        path: 'banking/transfers/incoming/create',
        component: () => import('./views/IncomingTransferForm.vue'),
    },
    {
        name: 'IncomingTransferUpdate',
        path: 'banking/transfers/incoming/:transfer',
        component: () => import('./views/IncomingTransferForm.vue'),
    },
    {
        name: 'OutgoingTransferList',
        path: 'banking/transfers/outgoing',
        component: () => import('./views/OutgoingTransferList.vue'),
        meta: {
            title: 'Outgoing Transfers',
            perms: 'outgoing_transfers',
            icon: 'fa-solid fa-exchange-alt',
        },
    },
    {
        name: 'OutgoingTransferCreate',
        path: 'banking/transfers/outgoing/create',
        component: () => import('./views/OutgoingTransferForm.vue'),
    },
    {
        name: 'OutgoingTransferUpdate',
        path: 'banking/transfers/outgoing/:transfer',
        component: () => import('./views/OutgoingTransferForm.vue'),
    },

    {
        name: 'InterbankTransferList',
        path: 'banking/transfers/interbank',
        component: () => import('./views/InterbankTransferList.vue'),
        meta: {
            title: 'Interbank Transfers',
            perms: 'interbank_transfers',
            icon: 'fa-solid fa-exchange-alt',
        },
    },
    {
        name: 'InterbankTransferCreate',
        path: 'banking/transfers/interbank/create',
        component: () => import('./views/InterbankTransferForm.vue'),
    },
    {
        name: 'InterbankTransferUpdate',
        path: 'banking/transfers/interbank/:transfer',
        component: () => import('./views/InterbankTransferForm.vue'),
    },

    {
        name: 'BankAccountList',
        path: 'banking/accounts',
        component: () => import('./views/BankAccountList.vue'),
        meta: {
            title: 'Bank Accounts',
            perms: 'finances',
            icon: 'fa-solid fa-money-check',
        },
    },

    {
        name: 'BankAccountCreate',
        path: 'banking/accounts/create',
        component: () => import('./views/BankAccountForm.vue'),
    },
    {
        name: 'BankAccountUpdate',
        path: 'banking/accounts/:account',
        component: () => import('./views/BankAccountForm.vue'),
    },

    {
        name: 'OrderDailyLogList',
        path: 'dailylogs',
        component: () => import('./views/OrderDailyLogList.vue'),
        meta: {
            title: 'Order Daily Log',
            perms: 'pos_balances',
            icon: 'fa-solid fa-calendar-days',
        },
    },
    {
        name: 'OrderDailyLogCreate',
        path: 'dailylogs/create',
        component: () => import('./views/OrderDailyLogForm.vue'),
    },
    {
        name: 'OrderDailyLogUpdate',
        path: 'dailylogs/:log',
        component: () => import('./views/OrderDailyLogForm.vue'),
    },
    {
        name: 'BankSummaryList',
        path: 'banking/summaries',
        component: () => import('./views/BankSummaryList.vue'),
        meta: {
            title: 'Bank Summaries',
            perms: 'bank_summaries',
            icon: 'fa-solid fa-calendar-days',
        },
    },
    {
        name: 'BankSummaryCreate',
        path: 'banking/summaries/create',
        component: () => import('./views/BankSummaryForm.vue'),
    },
    {
        name: 'BankSummaryUpdate',
        path: 'banking/summaries/:summary',
        component: () => import('./views/BankSummaryForm.vue'),
    },

    {
        name: 'CardSummaryList',
        path: 'banking/summaries/card',
        component: () => import('./views/CardSummaryList.vue'),
        meta: {
            title: 'Card Summaries',
            perms: 'bank_summaries',
            icon: 'fa-solid fa-calendar-days',
        },
    },
    {
        name: 'CardSummaryCreate',
        path: 'banking/summaries/card/create',
        component: () => import('./views/CardSummaryForm.vue'),
    },
    {
        name: 'CardSummaryUpdate',
        path: 'banking/summaries/card/:summary',
        component: () => import('./views/CardSummaryForm.vue'),
    },
]
