import * as Sentry from '@sentry/browser'

import { defineStore } from 'pinia'

export const useAuthStore = defineStore('auth', {
    state: () => ({
        token: null,
        groups: null,
        permissions: null,
        user: null,
    }),
    getters: {
        employeeId(state) {
            return state?.user?.employee?.pk
        },

        username(state) {
            if (state.user !== null) {
                return state.user.username
            }
            return ''
        },

        lang(state) {
            if (state.user !== null) {
                return state.user.lang
            }
            return 'en'
        },

        root(state) {
            if (state.user !== null) {
                return state.user.root || false
            }
            return false
        },

        hasPerm(state) {
            return (perm) => {
                if (state.permissions !== null) {
                    if (Array.isArray(perm)) {
                        return perm.some((x) => state.permissions.has(x))
                    }
                    return state.permissions.has(perm)
                }
                return false
            }
        },

        inGroup(state) {
            return (group) => {
                if (state.groups !== null) {
                    return state.groups.has(group)
                }
                return false
            }
        },
    },
    actions: {
        initAuth(credentials = undefined) {
            if (credentials) {
                this.token = credentials.auth_token
                this.groups = new Set(credentials.groups)
                this.permissions = new Set(credentials.permissions)
                this.user = credentials.user

                localStorage.setItem('AUTH-TOKEN', this.token)
                localStorage.setItem(
                    'AUTH-GROUPS',
                    JSON.stringify(credentials.groups)
                )
                localStorage.setItem(
                    'AUTH-PERMISSIONS',
                    JSON.stringify(credentials.permissions)
                )
                localStorage.setItem('AUTH-USER', JSON.stringify(this.user))
            } else {
                const token = localStorage.getItem('AUTH-TOKEN')
                if (token) {
                    this.token = token
                    this.groups = new Set(
                        JSON.parse(localStorage.getItem('AUTH-GROUPS'))
                    )
                    this.permissions = new Set(
                        JSON.parse(localStorage.getItem('AUTH-PERMISSIONS'))
                    )
                    this.user = JSON.parse(localStorage.getItem('AUTH-USER'))
                }
            }

            if (this.user) {
                Sentry.setUser({
                    id: this.user.pk,
                    username: this.user.username,
                    email: this.user.email,
                })
            }
        },

        clearAuth() {
            this.token = null
            this.groups = null
            this.permissions = null
            this.user = null

            localStorage.removeItem('AUTH-TOKEN')
            localStorage.removeItem('AUTH-GROUPS')
            localStorage.removeItem('AUTH-PERMISSIONS')
            localStorage.removeItem('AUTH-USER')

            Sentry.setUser(null)
        },
    },
})
