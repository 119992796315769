<template>
    <T3Page
        ref="page"
        :title="title"
        :hide-header="inline"
        :hide-back-button="inline"
        :inline="inline"
    >
        <slot name="modals"></slot>

        <template #actions>
            <slot name="extraActions"></slot>
            <T3Button
                v-if="$slots['filters']"
                icon="fas fa-sliders-h"
                :tooltip="showFilters ? 'Hide Filters' : 'Show Filters'"
                :role="showFilters ? 'primary' : undefined"
                @clicked="showFilters = !showFilters"
            />
        </template>

        <T3GroupBox
            v-show="showFilters"
            title="Filters"
        >
            <div class="columns is-marginless">
                <div class="column">
                    <slot name="filters"></slot>
                </div>
                <div class="column is-narrow">
                    <T3Button
                        :label="applyFilters ? 'Remove' : 'Apply'"
                        :tooltip="
                            applyFilters
                                ? 'Remove Filters'
                                : 'Apply Filter Filters'
                        "
                        :outlined="!applyFilters"
                        role="primary"
                        @clicked="onFiltersApplyButtonClicked"
                    />
                    <br />
                    <T3Button
                        label="Clear"
                        tooltip="Clear Filters"
                        outlined
                        @clicked="onFiltersClearButtonClicked"
                    />
                </div>
            </div>
        </T3GroupBox>

        <div
            v-if="
                $slots['aggregates'] !== undefined && aggregates !== undefined
            "
            class="data-aggregates"
        >
            <slot
                name="aggregates"
                :aggregates="aggregates"
            ></slot>
        </div>

        <div
            v-show="false"
            class="columns"
        >
            <slot></slot>
        </div>

        <DataTable
            ref="datatable"
            :pk-field="pkField"
            :fields="fields"
            :bordered="bordered"
            :items="items"
            :item-actions="localItemActions"
            :model-actions="$modelActions"
            :hide-topbar="hideTopbar"
            :show-index="showIndex"
            :current-index="currentIndex"
            :row-style="rowStyle"
            v-on="eventHandlers"
        >
            <template #pagination-header>
                <Pagination
                    v-if="pageCount > 1"
                    :page="page"
                    :page-count="pageCount"
                    :pagination="pagination"
                    @page-requested="onPageRequested"
                    @pagination-requested="onPaginationRequested"
                />
            </template>
            <template
                v-for="(_, slot) of $slots"
                #[slot]="scope"
            >
                <slot
                    :name="slot"
                    v-bind="scope"
                />
            </template>

            <template
                v-if="!hideTopbar"
                #dt-header
            >
                <tr
                    v-if="
                        !hideSearch || (capabilities?.ordering?.length || 0) > 0
                    "
                >
                    <td :colspan="columnCount">
                        <div class="columns is-gapless">
                            <div class="column">
                                <SearchBar
                                    v-if="!hideSearch"
                                    @perform-search="performSearch"
                                    @clear-search="clearSearch"
                                />
                            </div>
                            <div
                                v-if="capabilities.ordering.length > 0"
                                class="column is-narrow"
                            >
                                <OrderingCombo
                                    v-model="globalOrdering"
                                    :ordering="capabilities.ordering"
                                />
                            </div>
                        </div>
                    </td>
                </tr>
            </template>
            <template #pagination-footer>
                <Pagination
                    v-if="pageCount > 1"
                    :page="page"
                    :page-count="pageCount"
                    :pagination="pagination"
                    @page-requested="onPageRequested"
                    @pagination-requested="onPaginationRequested"
                />
            </template>
            <template #dt-footer>
                <slot
                    name="footer"
                    :aggregates="aggregates"
                ></slot>
            </template>
        </DataTable>
    </T3Page>
</template>

<script>
import Table from './Table'

import DataTable from './DataTable.vue'

import Pagination from './parts/Pagination.vue'
import SearchBar from './parts/SearchBar.vue'
import OrderingCombo from './parts/OrderingCombo.vue'

import FiltersMixin from './FiltersMixin'

export default {
    name: 'ListView',
    components: {
        DataTable,
        Pagination,
        SearchBar,
        OrderingCombo,
    },
    extends: Table,
    mixins: [FiltersMixin],
    props: {
        // Required
        endpoint: {
            type: String,
            required: true,
        },

        // Appearance
        hideSearch: {
            type: Boolean,
            default: false,
        },
        rowStyle: {
            type: [Object, Function],
            default: undefined,
        },

        // Behaviour
        noAutoloading: {
            type: Boolean,
            default: false,
        },

        pkField: {
            type: String,
            default: 'pk',
        },

        // Actions
        exportExcel: {
            type: String,
            default: undefined,
        },

        urlOnly: {
            type: Boolean,
            default: false,
        },

        // Page Props
        title: {
            type: String,
            default: undefined,
        },
        inline: {
            type: Boolean,
            default: false,
        },

        bordered: {
            type: Boolean,
            default: false,
        }
    },
    emits: ['aggregatesFetched'],
    data: () => ({
        // Control
        pagination: 50,

        // State
        page: 1,
        pageCount: 1,
        items: [],
        aggregates: undefined,

        searchingApplied: false,
        searchCriteria: '',
        globalOrdering: undefined,
        capabilities: {
            searchable: false,
            ordering: [],
        },
    }),
    computed: {
        $modelActions() {
            const actions = [...this.modelActions]
            if (this.exportExcel !== undefined) {
                actions.push({
                    type: 'export',
                    action: 'excel',
                    tooltip: 'Export to Excel',
                    icon: 'fas fa-file-excel',
                    role: 'info',
                    filename: this.exportExcel,
                })
            }
            return actions
        },
        eventHandlers() {
            const handlers = {}
            for (const button of this.localItemActions) {
                if (button.type === 'action') {
                    handlers[button.action] = (item) => {
                        this.$emit(button.action, item)
                    }
                }
            }
            for (const button of this.$modelActions) {
                if (button.type === 'action') {
                    handlers[button.action] = () => {
                        this.$emit(button.action)
                    }
                } else if (button.type === 'export') {
                    handlers['export'] = ({ action, filename }) => {
                        this.onExportRequested(action, filename)
                    }
                }
            }
            return handlers
        },
        allSelection() {
            return this.selectingAll
        },
        selectedItems() {
            return this.$refs.datatable.selectedItems
        },
        currentIndex() {
            return (this.page - 1) * this.pagination
        },
        localItemActions() {
            let result = []
            for (const action of this.itemActions) {
                action.type = action.type || 'action'
                result.push(action)
            }
            return result
        },
    },
    watch: {
        async globalOrdering() {
            await this.fetchItems()
        },
    },
    async created() {
        if (this.pkField === 'pk') {
            const capabilities = await this.$request(this.endpoint, {
                action: 'capabilities',
            })
            this.capabilities.searchable = capabilities?.searchable || false
            this.capabilities.ordering = capabilities?.ordering || []
        }

        if (!this.noAutoloading) {
            await this.fetchItems()
        }
    },
    mounted() {
        if (this.noAutoloading) {
            this.showFilters = true
        }
    },
    methods: {
        async fetchItems(page = undefined, search = '', _export = undefined) {
            try {
                const req = {
                    action: 'list',
                    page: page || this.page,
                    pagination: this.pagination,
                    ordering: this.globalOrdering,
                    filters: this.$filters,
                    export: _export ? _export.action : undefined,
                    url_only: this.urlOnly,
                    search,
                }

                if (this.pkField === 'pk') {
                    const response = await this.$request(
                        this.endpoint,
                        req,
                        _export ? _export.filename : undefined
                    )

                    if (response) {
                        this.page = page || this.page
                        this.pageCount = response.page_count
                        this.items = response.items
                        this.aggregates = response?.aggregates

                        if (this.aggregates) {
                            this.$emit('aggregatesFetched', this.aggregates)
                        }
                    }
                } else {
                    const params = {
                        limit: this.pagination,
                        offset:
                            ((page || this.page || 1) - 1) * this.pagination,
                    }

                    Object.entries(this.$filters).forEach(([key, value]) => {
                        if (value !== undefined) {
                            if (Array.isArray(value)) {
                                params[key] = value.join(',')
                            } else if (typeof value === 'object') {
                                params[key] = value.id
                            } else {
                                params[key] = value
                            }
                        }
                    })

                    if (search.trim() !== '') {
                        params.search = search
                    }

                    let response = null
                    if (_export?.action === 'excel') {
                        response = await this.$rest.get(
                            this.endpoint + '/excel',
                            params,
                            {
                                filename: _export?.filename || 'unknown.xlsx',
                            }
                        )
                    } else {
                        response = await this.$rest.get(this.endpoint, params)
                        const count = response.count

                        this.page = page || this.page
                        this.pageCount = Math.ceil(count / this.pagination)
                        this.items = response.items
                        this.aggregates = null
                    }
                }
            } catch (error) {
                this.msgError(error.reason)
            }
        },
        async onExportRequested(action, filename) {
            await this.fetchItems(
                1,
                this.searchingApplied ? this.searchCriteria : '',
                { action, filename }
            )
        },
        async performSearch(criteria) {
            this.searchingApplied = !this.searchingApplied
            this.searchCriteria = criteria
            await this.fetchItems(1, criteria)
        },
        async clearSearch() {
            if (this.searchingApplied) {
                this.searchingApplied = false
                this.searchCriteria = ''

                await this.fetchItems()
            }
        },
        async onPageRequested(page) {
            await this.fetchItems(
                page,
                this.searchingApplied ? this.searchCriteria : undefined
            )
        },
        async onPaginationRequested(pagination) {
            this.pagination = pagination
            await this.fetchItems(1)
        },
    },
}
</script>

<style scoped>
.button {
    margin-bottom: 5px;
}

.data-aggregates {
    padding: 0.8rem !important;
    margin: 0px !important;
    border: solid 1px #ea621f !important;
    background-color: #f8f8f8;
}

@media screen and (max-width: 600px) {
    .data-aggregates {
        width: 98% !important;
        margin-left: 1% !important;
    }
}
</style>
