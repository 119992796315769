import { formatCurrency } from './currency'

export const km = function (value) {
    if (value !== null && value !== undefined) {
        return `${value.toFixed(2)} km`
    }
    return ''
}

export const cityTax = function (value, type, currency = 'EUR') {
    switch (type) {
        case 'P': {
            return `${value.toFixed(2)}%`
        }
        case 'V': {
            const _value = formatCurrency(value, currency)
            return `${_value} per pax/night`
        }
        case 'R': {
            const _value = formatCurrency(value, currency)
            return `${_value} per room/night`
        }
    }
    return ''
}

export default function (app) {
    app.config.globalProperties.$fmt = {
        km,
        cityTax,
    }
}
