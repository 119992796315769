<template>
    <div class="flex flex-col w-full h-full justify-between items-center">
        <span
            v-if="label"
            class="label is-small text-left w-full"
        >
            {{ label }}
        </span>
        <div
            ref="editor"
            class="h-full w-full outline-0 overflow-y-scroll rounded p-4 bg-white"
            :class="
                bordered
                    ? 'border-[1px] border-solid border-[#DBDBDBFF] focus-within:border-primary'
                    : ''
            "
        ></div>
        <div
            class="flex gap-1 justify-center py-2 z-20"
            :class="classIsFloating"
        >
            <RichButton
                v-for="button in filteredButtons"
                :key="button.tooltip"
                :icon="button.icon"
                :tooltip="button.tooltip"
                @mousedown.prevent="executeCommand(button.action)"
            />
        </div>
    </div>
</template>

<script>
import RichButton from './RichTextAreaFieldButton.vue'

export default {
    name: 'RichTextAreaField',
    components: {
        RichButton,
    },
    props: {
        label: {
            type: String,
            default: '',
        },
        collapsed: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: String,
            default: '',
        },
        bordered: {
            type: Boolean,
            default: false,
        },
        floating: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        shadowRoot: null,
    }),
    computed: {
        filteredButtons() {
            const allButtons = [
                {
                    icon: 'fa-solid fa-rotate-left',
                    tooltip: 'Undo',
                    action: 'undo',
                },
                {
                    icon: 'fa-solid fa-rotate-right',
                    tooltip: 'Redo',
                    action: 'redo',
                },
                {
                    icon: 'fa-solid fa-bold',
                    tooltip: 'Bold',
                    action: 'bold',
                },
                {
                    icon: 'fa-solid fa-italic',
                    tooltip: 'Italic',
                    action: 'italic',
                },
                {
                    icon: 'fa-solid fa-underline',
                    tooltip: 'Underline',
                    action: 'underline',
                },
                {
                    icon: 'fa-solid fa-strikethrough',
                    tooltip: 'Strikethrough',
                    action: 'strike',
                },
                {
                    icon: 'fa-solid fa-align-left',
                    tooltip: 'Align left',
                    action: 'justifyLeft',
                },
                {
                    icon: 'fa-solid fa-align-center',
                    tooltip: 'Align center',
                    action: 'justifyCenter',
                },
                {
                    icon: 'fa-solid fa-align-right',
                    tooltip: 'Align right',
                    action: 'justifyRight',
                },
                {
                    icon: 'fa-solid fa-align-justify',
                    tooltip: 'Align justify',
                    action: 'justifyFull',
                },
                {
                    icon: 'fa-solid fa-outdent',
                    tooltip: 'Outdent',
                    action: 'outdent',
                },
                {
                    icon: 'fa-solid fa-indent',
                    tooltip: 'Indent',
                    action: 'indent',
                },
                {
                    icon: 'fa-solid fa-list-ul',
                    tooltip: 'Bullet list',
                    action: 'insertUnorderedList',
                },
                {
                    icon: 'fa-solid fa-list-ol',
                    tooltip: 'Ordered list',
                    action: 'insertOrderedList',
                },
                {
                    icon: 'fa-solid fa-paragraph',
                    tooltip: 'Paragraph',
                    action: 'p',
                },
                {
                    icon: 'fa-solid fa-heading',
                    tooltip: 'Heading',
                    action: 'h1',
                },
                {
                    icon: 'fa-solid fa-link',
                    tooltip: 'Insert link',
                    action: 'createlink',
                },
                {
                    icon: 'fa-solid fa-unlink',
                    tooltip: 'Remove link',
                    action: 'unlink',
                },
                {
                    icon: 'fa-solid fa-image',
                    tooltip: 'Insert image',
                    action: 'insertimage',
                },
                {
                    icon: 'fa-solid fa-subscript',
                    tooltip: 'Subscript',
                    action: 'subscript',
                },
                {
                    icon: 'fa-solid fa-superscript',
                    tooltip: 'Superscript',
                    action: 'superscript',
                },
            ]
            if (this.collapsed) {
                return allButtons.filter(
                    (button) =>
                        ![
                            'fa-solid fa-rotate-left',
                            'fa-solid fa-rotate-right',
                            'fa-solid fa-link',
                            'fa-solid fa-unlink',
                            'fa-solid fa-image',
                            'fa-solid fa-subscript',
                            'fa-solid fa-superscript',
                        ].includes(button.icon)
                )
            } else {
                return allButtons
            }
        },
        classIsFloating() {
            return this.floating ? '!fixed !bottom-[5px] !left-50%' : ''
        },
    },
    watch: {
        modelValue(newVal) {
            this.updateShadowDOM(newVal)
        },
    },
    mounted() {
        this.initShadowDOM()
    },
    methods: {
        initShadowDOM() {
            const host = this.$refs.editor
            this.shadowRoot = host.attachShadow({ mode: 'open' })
            this.updateShadowDOM(this.modelValue)
        },
        updateShadowDOM(content) {
            if (this.shadowRoot) {
                let editableDiv =
                    this.shadowRoot.querySelector('#editable-content')
                if (!editableDiv) {
                    editableDiv = document.createElement('div')
                    editableDiv.id = 'editable-content'
                    editableDiv.setAttribute('contenteditable', 'true')
                    this.shadowRoot.appendChild(editableDiv)
                    editableDiv.style.minHeight = '100%'
                    editableDiv.style.outline = '0'
                    editableDiv.addEventListener('input', () => {
                        this.$emit('update:modelValue', editableDiv.innerHTML)
                    })
                }
                if (editableDiv.innerHTML !== content) {
                    editableDiv.innerHTML = content
                }
            }
        },
        executeCommand(event) {
            const command = event
            if (command) {
                if (command === 'h1' || command === 'h2' || command === 'p') {
                    this.applyBlockFormat(command)
                } else if (
                    command === 'createlink' ||
                    command === 'insertimage'
                ) {
                    const url = prompt('Enter the link here: ', '')
                    if (url !== null) {
                        this.applyCommandWithArgument(command, url)
                    }
                } else {
                    this.applyCommand(command)
                }
            }
        },
        applyBlockFormat(tagName) {
            document.execCommand('formatBlock', false, tagName)
        },
        applyCommandWithArgument(command, argument) {
            document.execCommand(command, false, argument)
        },
        applyCommand(command) {
            document.execCommand(command, false, null)
        },
        setText(text) {
            this.$emit('update:modelValue', text)
            this.updateShadowDOM(text)
        },
    },
}
</script>
