export default [
    {
        name: 'PaymentList',
        path: 'payments',
        component: () => import('./views/PaymentList.vue'),
        meta: {
            title: 'Payments',
            perms: 'leads',
        },
    },
    {
        name: 'PendingIncomingPaymentList',
        path: 'payments/pending/incoming',
        component: () => import('./views/PendingIncomingPaymentList.vue'),
        meta: {
            title: 'Pending Incoming Payments',
            perms: 'leads',
        },
    },
    {
        name: 'PendingIncomingPaymentCalendar',
        path: 'payments/pending/incoming/calendar',
        component: () => import('./views/PendingIncomingPaymentCalendar.vue'),
        meta: {
            title: 'Pending Incoming Payments Calendar',
            perms: 'leads',
        },
    },
    {
        name: 'PendingOutgoingPaymentList',
        path: 'payments/pending/outgoing',
        component: () => import('./views/PendingOutgoingPaymentList.vue'),
        meta: {
            title: 'Pending Outgoing Payments',
            perms: 'leads',
        },
    },
    {
        name: 'PendingOutgoingPaymentCalendar',
        path: 'payments/pending/outgoing/calendar',
        component: () => import('./views/PendingOutgoingPaymentCalendar.vue'),
        meta: {
            title: 'Pending Outgoing Payments Calendar',
            perms: 'leads',
        },
    },
]
