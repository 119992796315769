<template>
    <div
        class="field"
        :class="extraFieldClasses"
    >
        <label
            v-if="label"
            class="label is-small pr-3"
            :class="{
                'has-text-right': rightAligned,
            }"
        >
            {{ labelText }}
        </label>

        <div
            class="control"
            :class="iconClasses"
        >
            <input
                v-bind="$attrs"
                class="input is-small number-field-input"
                :class="inputClasses"
                :disabled="readonly || undefined"
                :value="modelValue"
                @input="onInput"
                @blur="onBlur"
            />

            <span
                v-if="leftIcon !== undefined"
                class="icon is-left is-small"
            >
                <i :class="leftIcon" />
            </span>
            <span
                v-if="rightIcon !== undefined"
                class="icon is-right is-small"
            >
                <i :class="rightIcon" />
            </span>
        </div>
        <p
            v-for="error in errors"
            :key="error"
            class="help is-danger"
        >
            {{ error }}
        </p>
    </div>
</template>

<script>
import Field from './Field'
import InputIconsMixin from './InputIconsMixin'

export default {
    name: 'T3NumberField',
    extends: Field,
    mixins: [InputIconsMixin],
    props: {
        modelValue: {
            type: Number,
            default: null,
        },
        decimals: {
            type: Number,
            default: 2,
        },
        nullable: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        minValue: {
            type: Number,
            default: undefined,
            required: false,
        },
        maxValue: {
            type: Number,
            default: undefined,
        },
        extraFieldClasses: {
            type: String,
            default: '',
        },
    },
    emits: ['update:modelValue'],
    methods: {
        cleaned(value) {
            let replaced = value.replace(',', '.')
            let output = []
            let dot = false
            let decimals = 0
            for (let i = 0; i < replaced.length; i++) {
                const char = replaced.charAt(i)
                if (char === '-' && i === 0) {
                    output.push(char)
                } else if (char === '.' && this.decimals > 0 && !dot) {
                    if (i === 0) {
                        output.push('0.')
                    } else {
                        output.push(char)
                    }
                    dot = true
                } else if (char >= '0' && char <= '9') {
                    if (dot) {
                        decimals += 1
                    }
                    if (decimals > this.decimals) {
                        break
                    }
                    output.push(char)
                }
            }
            return output.join('')
        },
        fValue(value) {
            if (value.charAt(value.length - 1) === '.') {
                value = value.slice(0, value.length - 1)
            }

            if (this.nullable) {
                if (value === '' || value === '-') {
                    return null
                }
                return parseFloat(value)
            }

            if (value === '') {
                return null
            }
            if (value === '-') {
                return 0
            }
            return parseFloat(value)
        },
        isValid(value) {
            if (value) {
                if (this.minValue !== undefined && value < this.minValue) {
                    return false
                }

                if (this.maxValue !== undefined && value > this.maxValue) {
                    return false
                }
            } else {
                if (!this.nullable) {
                    return false
                }
            }

            return true
        },

        onInput(event) {
            const cleaned = this.cleaned(event.target.value)
            const value = this.fValue(cleaned)

            if (value === null) {
                this.$emit('update:modelValue', null)
            } else if (this.isValid(value)) {
                if (cleaned !== '-') {
                    if (cleaned !== null) {
                        this.$emit(
                            'update:modelValue',
                            value.toFixed(this.decimals)
                        )
                    }
                }
            }
            event.target.value = cleaned
        },
        onBlur(event) {
            const cleaned = this.cleaned(event.target.value)
            const value = this.fValue(cleaned)

            let formatted = null
            if (value !== null && this.isValid(value)) {
                formatted = value.toFixed(this.decimals)
            }

            if (formatted) {
                event.target.value = formatted
            } else {
                if (this.nullable) {
                    event.target.value = ''
                } else {
                    const zero = 0
                    formatted = zero.toFixed(this.decimals)
                    event.target.value = formatted
                }
            }

            this.$emit('update:modelValue', formatted)
        },
    },
}
</script>

<style scoped>
.number-field-input {
    text-align: right;
}
</style>
