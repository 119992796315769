<template>
    <T3TableView
        v-bind="$attrs"
        ref="table"
        :pk-field="pkField"
        :title="listTitle"
        :endpoint="endpoint"
        :fields="fields"
        :model-actions="$modelActions"
        :item-actions="$itemActions"
        :export-excel="exportExcel"
        :filters="filters"
        :static-filters="staticFilters"
        :hide-search="hideSearch"
        :inline="inline"
        :row-style="rowStyle"
        @delete="onDeleteClicked"
    >
        <template #filters>
            <slot name="filters"></slot>
        </template>

        <template #modals>
            <slot name="modals"></slot>

            <T3ConfirmationModal
                ref="deleteConfirmationModal"
                :title="`${modelName} Deletion Confirmation`"
                @confirmed="onDeleteConfirmed"
                @rejected="onDeleteRejected"
            >
                Are you sure that you want to remove the
                <strong>{{ modelName }}</strong> with ID
                <strong>
                    {{ selectedPk }}
                </strong>
                ?
            </T3ConfirmationModal>
        </template>

        <template
            v-for="(_, slot) of $slots"
            #[slot]="scope"
        >
            <slot
                :name="slot"
                v-bind="scope"
            />
        </template>
    </T3TableView>
</template>

<script>
export default {
    name: 'CRUDListPage',
    props: {
        pkField: {
            type: String,
            default: 'pk',
        },

        title: {
            type: String,
            default: undefined,
        },

        fields: {
            type: Array,
            required: true,
        },
        routePkParam: {
            type: String,
            required: true,
        },
        modelName: {
            type: String,
            required: true,
        },
        modelNamePlural: {
            type: String,
            required: true,
        },
        endpoint: {
            type: String,
            required: true,
        },

        // Actions
        exportExcel: {
            type: String,
            default: undefined,
        },
        modelActions: {
            type: Array,
            default: () => [],
        },
        itemActions: {
            type: Array,
            default: () => [],
        },

        // Filters
        filters: {
            type: Object,
            default: undefined,
        },
        staticFilters: {
            type: Object,
            default: () => ({}),
        },

        // Appearance
        hideSearch: {
            type: Boolean,
            default: false,
        },
        inline: {
            type: Boolean,
            default: false,
        },
        rowStyle: {
            type: [Object, Function],
            default: undefined,
        },

        // Buttons
        noCreate: {
            type: Boolean,
            default: false,
        },
        noEdit: {
            type: Boolean,
            default: false,
        },
        allowDelete: {
            type: Boolean,
            default: false,
        },
        showDetails: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        selectedPk: null,
    }),
    computed: {
        $modelActions() {
            if (this.noCreate) {
                return []
            }
            return [
                ...this.modelActions,
                {
                    type: 'link',
                    action: 'add',
                    icon: 'fa-solid fa-plus',
                    tooltip: `Add ${this.modelName}`,
                    role: 'info',
                    route: `${this.cleanedModelName}Create`,
                },
            ]
        },
        $itemActions() {
            let result = []
            if (this.showDetails) {
                result.push({
                    type: 'link',
                    icon: 'fas fa-eye',
                    tooltip: `Show ${this.modelName} Details`,
                    role: 'info',
                    route: this.detailsRoute,
                })
            }
            if (!this.noEdit) {
                result.push({
                    type: 'link',
                    icon: 'fas fa-edit',
                    tooltip: `Edit ${this.modelName}`,
                    role: 'info',
                    route: this.updateRoute,
                })
            }
            if (this.allowDelete) {
                result.push({
                    action: 'delete',
                    icon: 'fa-solid fa-trash',
                    tooltip: `Delete ${this.modelName}`,
                    role: 'danger',
                })
            }
            return this.itemActions.concat(result)
        },
        cleanedModelName() {
            return this.modelName.replace(/ /g, '')
        },
        listTitle() {
            if (this.title) {
                return this.title
            }
            return this.modelNamePlural
        },
    },
    methods: {
        detailsRoute(item) {
            const name = `${this.cleanedModelName}Details`
            const params = {}
            params[this.routePkParam] = item[this.pkField]
            return { name, params }
        },
        updateRoute(item) {
            const name = `${this.cleanedModelName}Update`
            const params = {}
            params[this.routePkParam] = item[this.pkField]
            return { name, params }
        },
        onDeleteClicked(item) {
            this.selectedPk = item[this.pkField]
            this.$refs.deleteConfirmationModal.open()
        },
        async onDeleteConfirmed() {
            if (this.pkField === 'pk') {
                const url = `${this.endpoint}/${this.selectedPk}/delete`
                await this.$request(url)
                await this.$refs.table.fetchItems()
                this.selectedPk = null
            } else if (this.pkField === 'id') {
                const url = `${this.endpoint}/${this.selectedPk}`
                await this.$rest.delete(url)
                await this.$refs.table.fetchItems()
                this.selectedPk = null
            }
        },
        onDeleteRejected() {
            this.selectedPk = null
        },
    },
}
</script>
